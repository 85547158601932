import { create } from 'zustand';

const useAuthStore = create((set) => ({
  isLoggedIn: false,
  isAuthLoading: false,
  logoutUrl: null,
  userDetails: null,
  loginUrl: null,
  setIsLogin: (value) => set((state) => ({ ...state, isLoggedIn: value })),
  setIsAuthLoading: (value) => set((state) => ({ ...state, isAuthLoading: value })),
  setLogoutUrl: (value) => set((state) => ({ ...state, logoutUrl: value })),
  setUserDetails: (value) => set((state) => ({ ...state, userDetails: value })),
  setLoginUrl: (value) => set((state) => ({ ...state, loginUrl: value })),
}));

export { useAuthStore };
