import React, { useState, useEffect, useRef } from 'react';
import {
  Navbar,
  truncate,
  BannerLayout,
  Footer,
  FilterGroup,
  queryKeys,
  fetchIdeaList,
  getFilters,
  getFeaturedIdeas,
  debounce,
  OIDC,
  ScriptIcon,
  cookie
} from '@devportal/common';
import {
  Card,
  CardContent,
  Pagination,
  InputAdornment,
  TextField,
  CircularProgress,
  Skeleton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { SearchIcon, CloseIcon } from '@diligentcorp/atlas-react-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { WithNavLayout } from 'components';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function IdeasHubMainPage(props) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const [filterCheckMap, setFilterCheckMap] = useState({});
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const accessToken = cookie.get('accessToken');
  const initialFilter = searchParams.get('has_scripts');
  const [selectedFilter, setSelectedFilter] = useState(
    initialFilter === 'y' ? 'Contains scripts' : initialFilter === 'n' ? "Doesn't Contain scripts" : 'Show All'
  );

  const { data: ideas, isLoading: isIdeaListLoading } = useQuery(
    [
      queryKeys.IDEA_LIST,
      {
        filter: searchParams?.get('filter') || '',
        searchText: searchParams?.get('search-text') || '',
        has_scripts: searchParams?.get('has_scripts') || '',
        recordsPerPage: searchParams?.get('records-per-page') || '10',
        page: searchParams?.get('page') || '1',
      },
    ],
    fetchIdeaList,
    {
      onSuccess: (data) => {
        setPagesCount(Math.ceil(Number(data?.data?.total / 10)));
      },
    }
  );

  const ideaList = ideas?.data?.attributes || [];

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFilter(event.target.value as string);
    if (event?.target?.value === 'Contains scripts') {
      searchParams.set('has_scripts', 'y');
    } else if (event?.target?.value === "Doesn't Contain scripts") {
      searchParams.set('has_scripts', 'n');
    } else {
      searchParams.delete('has_scripts');
    }
    searchParams.set('page', '1');
    queryUpdater();
  };

  const { data: filters, isLoading: isFiltersLoading } = useQuery(
    [
      queryKeys.IDEA_FILTERS,
      {
        filterPage: 'ideas',
      },
    ],
    getFilters
  );

  const { data: featuredIdeas, isLoading: isFeaturedLoading } = useQuery([queryKeys.IDEA_FEATURED], getFeaturedIdeas);

  const featuredIdeaData = featuredIdeas?.data?.attributes || [];
  const filtersGroup = {};

  filters?.data?.attributes?.forEach((item) => {
    filtersGroup[item.filter_type] = filtersGroup[item.filter_type] || [];
    filtersGroup[item.filter_type].push(item);
  });

  useEffect(() => {
    const filters = searchParams.get('filter');
    const arr = filters?.split('-') || [];

    setFilterCheckMap((olds) => {
      const oldsObj = JSON.parse(JSON.stringify(olds));
      Object.keys(oldsObj).forEach((key) => {
        oldsObj[key] = false;
      });
      arr.forEach((id) => {
        oldsObj[id] = true;
      });
      return oldsObj;
    });
  }, [window?.location?.href]);

  const queryUpdater = () => {
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const filtersParamUpdater = (updatedFilters) => {
    if (updatedFilters?.length) {
      searchParams.set('filter', updatedFilters.join('-'));
    } else {
      searchParams.delete('filter');
    }
    searchParams.set('page', '1');
    queryUpdater();
  };
  const onChangeHandler = (id, value) => {
    setFilterCheckMap((olds) => ({ ...olds, [id]: value }));
    const updatedFilters =
      searchParams
        ?.get('filter')
        ?.split('-')
        ?.filter((item) => item != id) || [];

    if (value) updatedFilters.push(id);

    filtersParamUpdater(updatedFilters);
  };

  const filterSectionClearHandler = (ids) => {
    setFilterCheckMap((olds) => {
      const oldsObj = JSON.parse(JSON.stringify(olds));
      ids.forEach((id) => {
        oldsObj[id] = false;
      });
      return oldsObj;
    });

    const updatedFilters =
      searchParams
        ?.get('filter')
        ?.split('-')
        ?.filter((item) => !ids.includes(item)) || [];

    filtersParamUpdater(updatedFilters);
  };

  const onSearchHandler = debounce((e) => {
    if (e?.target?.value?.length) {
      searchParams.set('search-text', e.target.value);
    } else {
      searchParams.delete('search-text');
    }
    searchParams.set('page', '1');
    queryUpdater();
  }, 1000);
  const onIdeaClick = (id) => {
    if (accessToken?.length) {
      history.push(`/ideashub/${id}`);
    } else {
      setIsLoginModal(id);
    }
  };

  const inpRef: any = useRef();
  useEffect(() => {
    const val = searchParams?.get('search-text');
    if (val && val?.length) {
      inpRef.current.value = val;
    }
  }, [window?.location?.href]);

  const modalLoginClick = async () => {
    window.localStorage.setItem('redirectPath', `${window.location.origin}/ideashub/${isLoginModal}`);
    setIsLoginLoading(true);
    const  url = await OIDC.signIn();
    const windowProps = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1200, height=800`;
    window.open(url, '_blank', windowProps);
  };

  return (
    <WithNavLayout>
      <Dialog fullScreen={false} open={!!isLoginModal} onClose={() => setIsLoginModal(false)}>
        <DialogTitle>
          Login Required
          <IconButton aria-label="ArrowRight" onClick={() => setIsLoginModal(false)} color="inherit">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Please log in to view this Script</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined">Request a Demo</Button>
          <Button variant="contained" disabled={isLoginLoading} autoFocus onClick={modalLoginClick}>
            Log in {isLoginLoading ? <CircularProgress size={20} /> : null}
          </Button>
        </DialogActions>
      </Dialog>

      <BannerLayout
        type="dark"
        breadcrumpList={[
          { name: 'Home', href: '/' },
          { name: 'IdeasHub', href: '/ideashub' },
        ]}
        title="IdeasHub"
        description="Get inspired with ideas to get you started with analysis, automation, integrations and reporting using Diligent One platform developer tools."
        isCardsLoading={isFeaturedLoading}
        cardAreaLabel="FEATURED IDEAS"
        cardsData={{
          list: featuredIdeaData,
          titleKey: 'ideas_title',
          descriptionKey: 'ideas_description',
          idKey: 'ideas_id',
          clickHandler: onIdeaClick,
          linkPrefix: '/ideas',
        }}
      />

      <div className="flex">
        <div className="w-80 py-8">
          <div className="flex justify-between items-center font-semibold px-10">
            <p className="text-black">FILTERS</p>
            {Object.values(filterCheckMap).some((item) => item) ? (
              <p
                className="text-xs text-brand-300 cursor-pointer"
                onClick={() => {
                  setFilterCheckMap({});
                  filtersParamUpdater([]);
                }}
              >
                Reset Filters
              </p>
            ) : null}
          </div>

          <div className="px-10">
            <div style={{ height: '1px', backgroundColor: '#D7D7D7' }}></div>
          </div>

          <div className="px-10 overflow-auto" style={{ maxHeight: '86vh' }}>
            {isFiltersLoading ? (
              Array.from(new Array(22)).map((i) => <Skeleton height={40} />)
            ) : !filters?.data?.attributes?.length ? (
              <>No data found</>
            ) : (
              Object.keys(filtersGroup).map((key) => {
                return (
                  <FilterGroup
                    heading={`Filter by ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                    onClear={() => {
                      filterSectionClearHandler(filtersGroup[key].map((item) => item.filter_id));
                    }}
                    items={filtersGroup[key]}
                    checkMap={filterCheckMap}
                    onChangeHandler={onChangeHandler}
                  />
                );
              })
            )}
          </div>
        </div>

        <div className="bg-lightgrey-100 w-full">
          <div className="container mx-auto w-[80%] py-10">
            <div className="flex h-full items-end mb-6">
              <TextField
                size="medium"
                className="w-full bg-white"
                placeholder="Search by Name, Description and tags..."
                onChange={onSearchHandler}
                inputRef={inpRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl sx={{ minWidth: 200, marginLeft: 2 }}>
                <div id="demo-simple-select-label" className="text-black text-base">
                  Ideas with Scripts
                </div>
                <Select
                  className="bg-white"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFilter}
                  onChange={handleChange}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <p>Show All</p>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value={'Show All'}>Show All</MenuItem>
                  <MenuItem value={'Contains scripts'}>Contains scripts</MenuItem>
                  <MenuItem value={"Doesn't Contain scripts"}>Doesn't contain scripts</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="overflow-auto" style={{ height: '80vh' }}>
              {isIdeaListLoading ? (
                <div className="flex justify-center items-center w-full">
                  <CircularProgress size={55} />
                </div>
              ) : !ideaList?.length ? (
                <div>No data found</div>
              ) : (
                ideaList.map((item, i) => {
                  return (
                    <Card
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      className={`cursor-pointer ${i == 0 ? '' : 'mt-3'}`}
                      onClick={() => {
                        onIdeaClick(item?.ideas_id);
                      }}
                    >
                      <CardContent>
                        <div className="flex relative">
                          <div className="w-130">
                            <div className="text-text-200 font-bold pb-4">{item?.ideas_title}</div>
                            <div className="text-sm">{truncate(item?.ideas_description, 340)}</div>
                          </div>
                          {item?.ideas_has_scripts && (
                            <button className="w-6 h-6 border-none bg-transparent absolute right-0">
                              <ScriptIcon svgProps={{ width: 24, height: 24 }} />
                            </button>
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  );
                })
              )}
            </div>
            <div className="flex justify-end mt-2">
              <Pagination
                count={Math.ceil(Number(ideas?.data?.total / 10)) || pagesCount}
                sx={{ p: 0 }}
                page={Number(searchParams?.get('page')) || 1}
                onChange={(e, page) => {
                  searchParams.set('page', String(page));
                  queryUpdater();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </WithNavLayout>
  );
}
export { IdeasHubMainPage };
