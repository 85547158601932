import { cookie } from '@devportal/common';

let API_URL;
let AUTH_PROVIDER;

async function loadApiDetails() {
  const { API_ENDPOINT, AUTH_PROVIDER: AUTH_PROVIDER_ENDPOINT }: any = await fetch('/envConfig.json').then((res) =>
    res.json()
  );

  API_URL = API_ENDPOINT || process.env.REACT_APP_API_URL || 'https://developer-hub-api.highbond.com';
  AUTH_PROVIDER = AUTH_PROVIDER_ENDPOINT || 'https://oidc.highbond.com';
  return { API_URL, AUTH_PROVIDER };
}

const fetchCodeSampleList = async ({ queryKey }) => {
  const { API_URL } = await loadApiDetails();
  const { filter, searchText, page, recordsPerPage } = queryKey[1];
  let queryParam = filter?.length ? `?filter_ids=${filter}` : '';

  if (searchText?.length) queryParam += `${queryParam?.length ? '&' : '?'}search_text=${searchText}`;
  if (recordsPerPage?.length) queryParam += `${queryParam?.length ? '&' : '?'}records_per_page=${recordsPerPage}`;
  if (page?.length && recordsPerPage?.length) {
    const offset = (Number(page) - 1) * recordsPerPage;
    queryParam += `${queryParam?.length ? '&' : '?'}offset=${String(offset)}`;
  }

  return (
    await fetch(`${API_URL}/code_samples${queryParam}`, {
      method: 'GET',
    })
  ).json();
};

const getFilters = async ({ queryKey }) => {
  const { API_URL } = await loadApiDetails();
  const { filterPage } = queryKey[1];
  return (
    await fetch(`${API_URL}/filters?filter_page=${filterPage}`, {
      method: 'GET',
    })
  ).json();
};

const getFeaturedCodeSamples = async () => {
  const { API_URL } = await loadApiDetails();
  return ( 
    await fetch(`${API_URL}/featured_code_samples`, {
      method: 'GET',
    })
  ).json();
};

const getCodeSampleDetails = async ({ queryKey }) => {
  const { sampleId } = queryKey[1];
  const token = cookie.get('accessToken');
  const { API_URL } = await loadApiDetails();

  return (
    await fetch(`${API_URL}/code_samples/${sampleId}`, {
      method: 'GET',
      headers:
        token && token?.length
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
    })
  ).json();
};

const getSignedUrl = async ({ queryKey }) => {
  const { filePath } = queryKey[1];
  const { API_URL } = await loadApiDetails();

  return (
    await fetch(`${API_URL}/signed_url`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: 'signed_url',
          attributes: {
            filePath,
          },
        },
      }),
    })
  ).json();
};

const fetchIdeaList = async ({ queryKey }) => {
  const { API_URL } = await loadApiDetails();
  const { filter, searchText, page, recordsPerPage, has_scripts } = queryKey[1];
  let queryParam = filter?.length ? `?filter_ids=${filter}` : '';

  if (searchText?.length) queryParam += `${queryParam?.length ? '&' : '?'}search_text=${searchText}`;
  if (has_scripts?.length) queryParam += `${queryParam?.length ? '&' : '?'}has_scripts=${has_scripts}`;
  if (recordsPerPage?.length) queryParam += `${queryParam?.length ? '&' : '?'}records_per_page=${recordsPerPage}`;
  if (page?.length && recordsPerPage?.length) {
    const offset = (Number(page) - 1) * recordsPerPage;
    queryParam += `${queryParam?.length ? '&' : '?'}offset=${String(offset)}`;
  }

  return (
    await fetch(`${API_URL}/ideas${queryParam}`, {
      method: 'GET',
    })
  ).json();
};

const getFeaturedIdeas = async () => {
  const { API_URL } = await loadApiDetails();
  (
    await fetch(`${API_URL}/featured_ideas`, {
      method: 'GET',
    })
  ).json();
};

const getIdeaDetails = async ({ queryKey }) => {
  const { ideaId } = queryKey[1];
  const token = cookie.get('accessToken');
  const { API_URL } = await loadApiDetails();

  return (
    await fetch(`${API_URL}/ideas/${ideaId}`, {
      method: 'GET',
      headers:
        token && token?.length
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
    })
  ).json();
};

export {
  fetchCodeSampleList,
  getFilters,
  getFeaturedCodeSamples,
  getCodeSampleDetails,
  getSignedUrl,
  fetchIdeaList,
  getFeaturedIdeas,
  getIdeaDetails,
  loadApiDetails,
};
