import React, { Children } from 'react';
import lightbanner from '../../images/whiteherobanner.png';
import darkbanner from '../../images/darkherobanner.png';
import brandbanner from '../../images/homeherobanner.png';
import { Link, Skeleton, Breadcrumbs } from '@mui/material';
import { truncate } from '../../utils';
import { ArrowLink } from '@devportal/common';

function BannerLayout({
  children,
  type,
  breadcrumpList,
  title,
  description,
  cardsData,
  cardAreaLabel,
  isCardsLoading,
  isDataLoading,
}: {
  children?: React.ReactNode;
  type: 'dark' | 'light' | 'brand';
  breadcrumpList?: { name: string; href: string }[];
  title?: string;
  isDataLoading?: boolean;
  description?: string;
  isCardsLoading?: boolean;
  cardsData?: {
    titleKey: string;
    descriptionKey: string;
    linkPrefix: string;
    idKey: string;
    clickHandler?: Function;
    list: Array<{
      [key: string]: string;
    }>;
  };
  cardAreaLabel?: string;
}) {
  const bannerMap = {
    dark: darkbanner,
    light: lightbanner,
    brand: brandbanner,
  };

  const breadCrumbsHoverColors = {
    dark: 'white',
    light: '#0D0C38',
  }

  const textColors = {
    dark: 'text-white',
    light: 'text-text-800',
  }

  return (
    <div
      style={{
        backgroundImage: `url(${bannerMap[type]})`,
      }}
      className="bg-cover"
    >
      <div className="container mx-auto px-2 py-20">
        <div className="">
          {breadcrumpList?.length ? (
            <Breadcrumbs aria-label="breadcrumb">
              {breadcrumpList.map((item, i) => (
                <Link
                  underline="hover"
                  color={type === 'dark' ? 'white' : breadcrumpList.length - 1 == i ? 'text.primary' : 'inherit'}
                  href={item.href}
                  sx={{ '&:hover': { color: breadCrumbsHoverColors[type] } }} 
                >
                  {isDataLoading ? <Skeleton height={20} width={40} /> : item.name}
                </Link>
              ))}
            </Breadcrumbs>
          ) : null}

          {title?.length ? (
            <h1 className={`font-bold text-5xl ${textColors[type]}`}>{title}</h1>
          ) : isDataLoading ? (
            <Skeleton variant="rectangular" width={500} height={40} className="mt-2" />
          ) : null}
          {description?.length ? <div className={`text-lg ${textColors[type]}`}>{description}</div> : null}

          {cardsData ? (
            <div className="mt-12">
              <div className={`font-semibold mb-2 ${textColors[type]}`}>{cardAreaLabel}</div>
              <div className="flex justify-between gap-3 overflow-auto">
                {isCardsLoading ? (
                  Array.from(new Array(5)).map((i) => (
                    <div
                      className="min-w-57 rounded-lg min-h-46 flex flex-col items-center justify-between bg-white p-3"
                      style={{ borderTop: '4px solid', borderColor: '#D3222A' }}
                    >
                      <Skeleton variant="rectangular" width={200} height={8} />
                      <Skeleton variant="rectangular" width={200} height={8} />
                      <Skeleton variant="rectangular" width={200} height={80} />
                      <Skeleton variant="rectangular" width={200} height={8} />
                      <Skeleton variant="rectangular" width={200} height={8} />
                    </div>
                  ))
                ) : !cardsData?.list?.length ? (
                  <>No data found</>
                ) : (
                  cardsData?.list?.map((card) => (
                    <div
                      className="min-w-57 rounded-lg min-h-46 flex flex-col justify-between bg-white"
                      style={{ borderTop: '4px solid', borderColor: '#D3222A' }}
                    >
                      <div className="p-6 flex flex-col justify-between h-full">
                        <div className="text-text-800">
                          <p className="font-bold">{truncate(card[cardsData.titleKey], 39)}</p>
                          <p className="text-sm">{truncate(card[cardsData.descriptionKey], 55)}</p>
                        </div>
                        <div className="flex justify-end">
                          <ArrowLink
                            name="Learn more"
                            isUnderline={false}
                            wrapperProps={{ className: 'text-brand-300 font-bold' }}
                            onClick={() => {
                              if (cardsData?.clickHandler) cardsData?.clickHandler(card[cardsData?.idKey]);
                            }}
                            href={cardsData?.clickHandler ? '#' : `${cardsData.linkPrefix}/${card[cardsData.idKey]}`}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : null}

          {children || null}
        </div>
      </div>
    </div>
  );
}

export { BannerLayout };
