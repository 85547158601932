import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  HomePage,
  IdeasHubMainPage,
  IdeasHubDetailsPage,
  CodeSamplesMainPage,
  CodeSamplesDetailsPage,
  OidcCallbackPage,
} from 'pages';
import { AtlasThemeProvider } from '@diligentcorp/atlas-theme-mui';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'queries';
import { atlasThemeOverride } from 'utils';
import { StyledEngineProvider } from '@mui/material/styles';
import 'assets/main.css';

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <AtlasThemeProvider themeExtension={atlasThemeOverride}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/ideashub" component={IdeasHubMainPage} />
              <Route exact path="/ideashub/:id" component={IdeasHubDetailsPage} />
              <Route exact path="/code-samples" component={CodeSamplesMainPage} />
              <Route exact path="/code-samples/:id" component={CodeSamplesDetailsPage} />
              <Route exact path="/oidc/callback" component={OidcCallbackPage} />
            </Switch>
          </BrowserRouter>
        </QueryClientProvider>
      </AtlasThemeProvider>
    </StyledEngineProvider>
  );
}
