import React from 'react';
type SvgProps = {
  width?: number;
  height?: number;
};
const YouTubeIcon: React.FC<SvgProps> = ({ width = 32, height = 33 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2_8195)">
        <path
          d="M16 32.3477C24.6156 32.3477 31.6 25.3633 31.6 16.7477C31.6 8.13206 24.6156 1.14771 16 1.14771C7.38435 1.14771 0.399994 8.13206 0.399994 16.7477C0.399994 25.3633 7.38435 32.3477 16 32.3477Z"
          stroke="white"
        />
        <path
          d="M20.4168 12.6439C21.092 12.8319 21.5528 13.2919 21.74 13.9671C21.8208 14.2599 21.876 14.6319 21.9104 15.1023C21.9488 15.5383 21.9672 15.9327 21.9664 16.2511L22 16.7479C22 17.9575 21.9128 18.8935 21.74 19.5287C21.5528 20.2039 21.092 20.6647 20.416 20.8519C20.1096 20.9367 19.6128 20.9887 18.8544 21.0231C18.16 21.0607 17.5008 21.0791 16.888 21.0791L16 21.1111C13.7096 21.1111 12.2256 21.0263 11.5832 20.8519C10.908 20.6639 10.4472 20.2039 10.26 19.5279C10.1792 19.2359 10.124 18.8639 10.0896 18.3935C10.0512 17.9575 10.0328 17.5631 10.0336 17.2447L10 16.7479C10 15.5383 10.0872 14.6023 10.26 13.9671C10.4472 13.2919 10.908 12.8311 11.584 12.6439C11.8904 12.5591 12.3872 12.5071 13.1456 12.4727C13.84 12.4351 14.4992 12.4167 15.112 12.4167L16 12.3855C18.2904 12.3855 19.7744 12.4703 20.4168 12.6447V12.6439ZM16.0208 13.4759L15.1328 13.5087C14.5192 13.5087 13.8792 13.5263 13.1992 13.5623C12.516 13.5943 12.0784 13.6399 11.8752 13.6959C11.5688 13.7815 11.396 13.9535 11.312 14.2575C11.1696 14.7791 11.0912 15.6279 11.0896 16.7095L11.124 17.2071C11.124 17.5311 11.14 17.8935 11.1768 18.3063C11.2072 18.7183 11.2528 19.0263 11.3112 19.2375C11.396 19.5439 11.5688 19.7159 11.872 19.8007C12.3816 19.9391 13.8144 20.0215 15.9792 20.0215L16.8672 19.9887C17.4808 19.9887 18.1208 19.9711 18.8008 19.9351C19.484 19.9031 19.9216 19.8575 20.1248 19.8015C20.4312 19.7159 20.604 19.5439 20.688 19.2399C20.8304 18.7183 20.9088 17.8695 20.9104 16.7879L20.876 16.2903C20.876 15.9663 20.86 15.6039 20.8232 15.1911C20.7928 14.7791 20.7472 14.4711 20.6888 14.2599C20.604 13.9535 20.4312 13.7815 20.128 13.6967C19.6184 13.5583 18.1856 13.4759 16.0208 13.4759ZM14.9008 15.4991C14.9008 15.3071 15.112 15.1895 15.2752 15.2943L17.2568 16.5535C17.408 16.6503 17.408 16.8695 17.2568 16.9647L15.2752 18.2255C15.112 18.3303 14.9 18.2135 14.9 18.0215L14.9008 15.4991Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_8195">
          <rect width="32" height="32" fill="white" transform="translate(0 0.747803)" />
        </clipPath>
      </defs>
    </svg>
  );
};
const LinkedinIcon: React.FC<SvgProps> = ({ width = 32, height = 33 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2_8189)">
        <path
          d="M16 32.3477C24.6157 32.3477 31.6 25.3633 31.6 16.7477C31.6 8.13206 24.6157 1.14771 16 1.14771C7.38438 1.14771 0.400024 8.13206 0.400024 16.7477C0.400024 25.3633 7.38438 32.3477 16 32.3477Z"
          stroke="white"
        />
        <path
          d="M18.28 14.7397C19.9576 14.7397 20.2664 15.8445 20.2664 17.2797V20.2141H18.6096V17.5245C18.6064 16.9205 18.5568 16.1997 17.7472 16.1997C16.8848 16.1997 16.7528 16.8733 16.7528 17.5693V20.2141H15.0968V14.8733H16.6872V15.6013H16.7112C16.932 15.1821 17.4728 14.7389 18.2792 14.7389L18.28 14.7397ZM14.0576 14.8733V20.2141H12.3984V14.8733H14.0576ZM13.2272 12.2141C13.7576 12.2141 14.1872 12.6445 14.188 13.1749C14.188 13.7053 13.7576 14.1445 13.228 14.1445C12.6968 14.1445 12.2664 13.7053 12.2664 13.1749C12.2664 12.6445 12.6968 12.2149 13.2272 12.2149V12.2141Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_8189">
          <rect width="32" height="32" fill="white" transform="translate(0 0.747803)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TwitterIcon: React.FC<SvgProps> = ({ width = 32, height = 33 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2_8192)">
        <path
          d="M16 32.3477C24.6156 32.3477 31.6 25.3633 31.6 16.7477C31.6 8.13206 24.6156 1.14771 16 1.14771C7.38435 1.14771 0.399994 8.13206 0.399994 16.7477C0.399994 25.3633 7.38435 32.3477 16 32.3477Z"
          stroke="white"
        />
        <path
          d="M16.648 15.9454C16.648 15.9454 14.6704 16.4046 12.4632 13.8246C12.4632 13.8246 11.6032 15.5158 13.0936 16.5478C13.0936 16.5478 12.6064 16.6334 12.2056 16.3478C12.2056 16.3478 12.0328 17.8086 13.8104 18.3822H12.864C12.864 18.3822 13.1224 19.5574 14.7848 19.815C14.7848 19.815 14.0968 20.7326 11.8608 20.675C11.8608 20.675 13.1224 21.6494 15.0432 21.6206C15.0432 21.6206 18.5968 21.7926 20.288 17.7806C20.704 16.7926 20.6608 15.8598 20.632 15.4006L21.7216 14.4262L20.632 14.627C20.632 14.627 21.3776 14.083 21.464 13.567L20.26 14.0542C20.26 14.0542 19.356 13.2366 17.8232 13.5662C17.8232 13.5662 16.4184 13.8534 16.6472 15.9454H16.648ZM17.768 12.171C18.8048 11.967 19.736 12.115 20.5208 12.4934C20.564 12.5142 20.6056 12.535 20.6448 12.5558L21.4136 12.2446C22.2056 11.9246 23.0424 12.5942 22.9032 13.4366C23.28 13.851 23.34 14.543 22.8304 14.9982L22.0416 15.7038C22.052 16.571 21.9328 17.3486 21.5672 18.2158C20.9168 19.7598 19.9832 20.9214 18.8408 21.7302C18.0328 22.3014 17.1696 22.6638 16.2992 22.8582C15.7368 22.9854 15.2592 23.0246 14.908 23.0158C13.836 23.0278 12.8488 22.8014 11.9672 22.4158C11.4432 22.1862 11.0584 21.9486 10.8264 21.7702C9.9896 21.1238 10.4664 19.7846 11.5232 19.811C11.7264 19.8166 11.916 19.8134 12.0936 19.803C11.7576 19.4038 11.5784 19.003 11.5064 18.6726C11.4808 18.5582 11.4744 18.4446 11.4848 18.335C11.4152 18.2502 11.3504 18.163 11.2896 18.0726C10.864 17.4318 10.7368 16.7726 10.772 16.2102C10.776 16.1542 10.776 16.1542 10.78 16.1214C10.7992 15.9582 10.852 15.8102 10.9288 15.683C10.7024 14.9614 10.7536 14.2358 10.9672 13.5526C11.036 13.3302 11.1096 13.1566 11.1688 13.0406C11.5216 12.3454 12.468 12.2326 12.9744 12.8246C13.772 13.7574 14.56 14.3126 15.312 14.5806C15.4048 14.6142 15.4944 14.6422 15.58 14.6654C15.82 13.155 16.8136 12.2518 17.7688 12.171H17.768ZM12.6616 15.383H12.6592H12.6616Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_8192">
          <rect width="32" height="32" fill="white" transform="translate(0 0.747803)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const FacebookIcon: React.FC<SvgProps> = ({ width = 32, height = 33 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2_8186)">
        <path
          d="M16 32.3477C24.6157 32.3477 31.6 25.3633 31.6 16.7477C31.6 8.13206 24.6157 1.14771 16 1.14771C7.38438 1.14771 0.400024 8.13206 0.400024 16.7477C0.400024 25.3633 7.38438 32.3477 16 32.3477Z"
          stroke="white"
        />
        <path
          d="M19.52 11.1479V12.1079C19.52 12.5495 19.1616 12.9079 18.72 12.9079H17.6C17.1584 12.9079 16.96 13.4263 16.96 13.8679V15.4679H18.08C18.5216 15.4679 18.88 15.8263 18.88 16.2679V17.2279C18.88 17.6695 18.5216 18.0279 18.08 18.0279H16.96V22.3479C16.96 22.7895 16.6016 23.1479 16.16 23.1479H15.2C14.7584 23.1479 14.4 22.7895 14.4 22.3479V18.0279H13.28C12.8384 18.0279 12.48 17.6695 12.48 17.2279V16.2679C12.48 15.8263 12.8384 15.4679 13.28 15.4679H14.4V12.9079C14.4 11.4943 15.5464 10.3479 16.96 10.3479H18.72C19.1616 10.3479 19.52 10.7063 19.52 11.1479Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_8186">
          <rect width="32" height="32" fill="white" transform="translate(0 0.747803)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const RightArrowIcon: React.FC<SvgProps> = ({ width = 32, height = 33 }) => {
  return (
    <div className="ml-1.4 mt-[0.3rem]">
      <svg width={width} height={height} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 3.50024C0.223858 3.50024 0 3.7241 0 4.00024C0 4.27639 0.223858 4.50024 0.5 4.50024H8.29265L5.64645 7.14645C5.45118 7.34171 5.45118 7.65829 5.64645 7.85355C5.84171 8.04882 6.15829 8.04882 6.35355 7.85355L9.84412 4.36298C9.94013 4.27187 10 4.14305 10 4.00024C10 3.96629 9.99662 3.93313 9.99017 3.90108C9.97143 3.80778 9.92589 3.71879 9.85355 3.64645L6.35355 0.146447C6.15829 -0.0488157 5.84171 -0.0488157 5.64645 0.146447C5.45118 0.341708 5.45118 0.658291 5.64645 0.853553L8.29314 3.50024L0.5 3.50024Z"
          fill="#385F99"
        />
      </svg>
    </div>
  );
};

const BoxIcon = ({ width = 13.5, height = 13.5 }) => {
  return (
    <svg width={width} height={height} aria-hidden="true" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
      />
    </svg>
  );
};

const LinkIcon = ({ target, margintop = '0.1rem', marginleft = '0.3rem', className = 'flex flex-row' }) => {
  if (target === '_blank')
    return (
      <div
        className={`${className}`}
        style={{
          marginTop: margintop,
          marginLeft: marginleft,
        }}
      >
        <BoxIcon />
      </div>
    );
  else if (target === '_self') return <RightArrowIcon width={10} height={8} />;
  else return <></>;
};

const LoginIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...svgProps}>
      <path
        d="M15 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15"
        stroke="#E00D00"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
      <path
        d="M10 17L15 12L10 7"
        stroke="#E00D00"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
      <path d="M15 12H3" stroke="#E00D00" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" {...pathProps} />
    </svg>
  );
};

const ToolKitIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={65} height={65} viewBox="0 0 65 65" fill="none" {...svgProps}>
      <path
        d="M48.5565 20.2297L28.6067 40.1796L34.4767 46.0496L30.8569 49.6694L27.5469 46.3593L16.5569 57.3493C15.556 58.3502 13.9355 58.3502 12.9371 57.3493L7.81718 52.2294C6.81623 51.2284 6.81623 49.6105 7.81718 48.6096L18.8071 37.6196L15.4971 34.3096L19.1169 30.6898L24.9869 36.5598L44.9367 16.61L42.9067 14.5799L53.1465 6.89999L58.2665 12.0199L50.5866 22.2598L48.5565 20.2297Z"
        fill="#D3222A"
        {...pathProps}
      />
      <path
        d="M17.3065 27.3797C19.2009 27.3797 20.9545 26.8293 22.4776 25.9308L27.2366 30.6898L30.8564 27.07L26.0974 22.311C26.996 20.7878 27.5464 19.0342 27.5464 17.1399C27.5464 11.4823 22.9615 6.89999 17.3065 6.89999C15.4121 6.89999 13.6585 7.45039 12.1328 8.34894L19.1164 15.33L15.4966 18.9498L8.51557 11.9662C7.61702 13.4919 7.06663 15.2429 7.06663 17.1399C7.06663 22.7897 11.6515 27.3797 17.3065 27.3797Z"
        fill="#D3222A"
        {...pathProps}
      />
      <path
        d="M48.0263 37.6196C46.1319 37.6196 44.3783 38.17 42.8526 39.0686L38.0962 34.3096L34.4764 37.9294L39.2354 42.6858C38.3368 44.2115 37.7864 45.9625 37.7864 47.8595C37.7864 53.5093 42.3713 58.0994 48.0263 58.0994C49.9207 58.0994 51.6742 57.549 53.1974 56.6504L46.2164 49.6694L49.8362 46.0496L56.8172 53.0306C57.7158 51.5074 58.2662 49.7539 58.2662 47.8595C58.2662 42.202 53.6813 37.6196 48.0263 37.6196Z"
        fill="#D3222A"
        {...pathProps}
      />
    </svg>
  );
};

const AutoAnalysisIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={65} viewBox="0 0 64 65" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8116 20.6057V11.4686H10.9687V52.5858H30.3852V57.1543H10.9687C8.44915 57.1543 6.40015 55.1053 6.40015 52.5858V11.4686C6.40015 8.94544 8.44557 6.90002 10.9687 6.90002H37.0416L47.5173 17.3735V26.3119H42.9487V20.6057H33.8116ZM15.5373 45.7375H26.5339C26.0656 44.2984 25.8144 42.7633 25.8144 41.1689H20.1036V39.8303L24.6722 35.2617L26.4083 36.9978C26.8628 35.4381 27.5713 33.9641 28.5052 32.6348L26.2895 30.419C25.3975 29.5273 23.9515 29.5273 23.0595 30.419L20.1059 33.3703V25.1789H15.5373V45.7375ZM50.183 47.4507C51.38 45.6461 52.0858 43.4874 52.0858 41.1643C52.0858 34.8665 46.9622 29.7429 40.6644 29.7429C34.3666 29.7429 29.243 34.8665 29.243 41.1643C29.243 47.4598 34.3666 52.5858 40.6644 52.5858C42.9898 52.5858 45.1484 51.8776 46.953 50.6807L54.3701 58.1L57.6001 54.87L50.183 47.4507ZM40.6646 48.0172C36.8864 48.0172 33.8117 44.9425 33.8117 41.1643C33.8117 37.3838 36.8864 34.3115 40.6646 34.3115C44.4428 34.3115 47.5175 37.3838 47.5175 41.1643C47.5175 44.9425 44.4428 48.0172 40.6646 48.0172Z"
        fill="#D3222A"
        {...pathProps}
      />
    </svg>
  );
};

const BranchIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={65} height={65} viewBox="0 0 65 65" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9734 23.5399C44.8774 23.5399 41.5494 26.3559 40.5254 29.9399H34.8934V19.6999C34.8934 15.3479 31.5654 12.0199 27.2134 12.0199H21.5814C20.5574 8.94793 17.7414 6.89993 14.4134 6.89993C10.0614 6.89993 6.7334 10.2279 6.7334 14.5799C6.7334 18.9319 10.0614 22.2599 14.4134 22.2599C17.7414 22.2599 20.5574 20.2119 21.5814 17.1399H27.2134C28.7494 17.1399 29.7734 18.1639 29.7734 19.6999V45.2999C29.7734 46.8359 28.7494 47.8599 27.2134 47.8599H21.5814C20.5574 44.7879 17.7414 42.7399 14.4134 42.7399C10.0614 42.7399 6.7334 46.0679 6.7334 50.4199C6.7334 54.7719 10.0614 58.0999 14.4134 58.0999C17.7414 58.0999 20.5574 56.0519 21.5814 52.9799H27.2134C31.5654 52.9799 34.8934 49.6519 34.8934 45.2999V35.0599H40.5254C41.5494 38.6439 45.1334 41.4599 48.9734 41.4599C53.8374 41.4599 57.9334 37.3639 57.9334 32.4999C57.9334 27.6359 53.8374 23.5399 48.9734 23.5399ZM14.4134 17.1399C12.8774 17.1399 11.8534 16.1159 11.8534 14.5799C11.8534 13.0439 12.8774 12.0199 14.4134 12.0199C15.9494 12.0199 16.9734 13.0439 16.9734 14.5799C16.9734 16.1159 15.9494 17.1399 14.4134 17.1399ZM14.4134 52.9799C12.8774 52.9799 11.8534 51.9559 11.8534 50.4199C11.8534 48.8839 12.8774 47.8599 14.4134 47.8599C15.9494 47.8599 16.9734 48.8839 16.9734 50.4199C16.9734 51.9559 15.9494 52.9799 14.4134 52.9799ZM48.9734 36.3399C46.9254 36.3399 45.1334 34.5479 45.1334 32.4999C45.1334 30.4519 46.9254 28.6599 48.9734 28.6599C51.0214 28.6599 52.8134 30.4519 52.8134 32.4999C52.8134 34.5479 51.0214 36.3399 48.9734 36.3399Z"
        fill="#D3222A"
        {...pathProps}
      />
    </svg>
  );
};

const DocumentCurlIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={65} viewBox="0 0 54 65" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2609 29.5309V35.4353H12.3087V41.3398H15.2609V47.2442C15.2609 48.8767 16.5835 50.1964 18.2131 50.1964H24.1176V44.292H21.1653V32.4831H24.1176V26.5787H18.2131C16.5835 26.5787 15.2609 27.9013 15.2609 29.5309Z"
        fill="white"
        {...pathProps}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8787 29.5309C38.8787 27.9013 37.5561 26.5787 35.9265 26.5787H30.0221V32.4832H32.9743V44.292H30.0221V50.1964H35.9265C37.5561 50.1964 38.8787 48.8768 38.8787 47.2442V41.3398H41.8309V35.4354H38.8787V29.5309Z"
        fill="white"
        {...pathProps}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40435 59.0442L6.40435 5.90442L35.9264 5.90442V17.7133H47.7353L47.7382 59.0442H6.40435ZM40.1009 0L6.40442 0C3.14813 0 0.5 2.64518 0.5 5.90442L0.5 59.0442C0.5 62.3005 3.14813 64.9486 6.40442 64.9486H47.7354C50.9916 64.9486 53.6398 62.3005 53.6398 59.0442V13.5388L40.1009 0Z"
        fill="white"
        {...pathProps}
      />
    </svg>
  );
};

const ScriptIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} viewBox="5 0 65 64" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9795 16.64H47.8595V14.08C47.8595 12.6694 49.0089 11.52 50.4195 11.52C51.83 11.52 52.9795 12.6694 52.9795 14.08V16.64ZM42.7397 47.36C42.7397 50.1837 40.4434 52.48 37.6197 52.48C34.796 52.48 32.4997 50.1837 32.4997 47.36C32.4997 44.5363 34.796 42.24 37.6197 42.24C39.0328 42.24 40.1797 41.0931 40.1797 39.68C40.1797 38.2669 39.0328 37.12 37.6197 37.12H22.2597V14.08C22.2597 12.6694 23.4092 11.52 24.8197 11.52H43.2108C42.924 12.3238 42.7397 13.1789 42.7397 14.08V47.36ZM28.7547 52.48H17.14C14.3163 52.48 12.02 50.1837 12.02 47.36C12.02 44.5363 14.3163 42.24 17.14 42.24H28.7547C27.8792 43.7478 27.38 45.4963 27.38 47.36C27.38 49.2237 27.8792 50.9722 28.7547 52.48ZM50.4197 6.40002H24.8197C20.5855 6.40002 17.1397 9.84578 17.1397 14.08V37.12C11.4924 37.12 6.89972 41.7127 6.89972 47.36C6.89972 53.0074 11.4924 57.6 17.1397 57.6H37.6197C43.2671 57.6 47.8597 53.0074 47.8597 47.36V21.76H55.5397C56.9528 21.76 58.0997 20.6131 58.0997 19.2V14.08C58.0997 9.84578 54.654 6.40002 50.4197 6.40002Z"
        fill="#C42125"
        {...pathProps}
      />
    </svg>
  );
};

const ForkIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} viewBox="5 0 65 64" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1805 28.7924L61.2999 19.5962L52.1805 10.4L48.7019 13.9078L51.8828 17.1154H45.5211C35.9145 17.1154 33.361 20.8093 31.11 24.069C29.0903 26.9938 27.3437 29.5193 18.4604 29.5193H3.69995V34.4808H18.4604C27.3437 34.4808 29.0903 37.0062 31.11 39.931C33.361 43.1907 35.9145 46.8846 45.5211 46.8846H51.8828L48.7019 50.0922L52.1805 53.6L61.2999 44.4038L52.1805 35.2076L48.7019 38.7154L51.8828 41.9231H45.5211C38.4828 41.9231 37.0831 39.8988 35.147 37.0955C33.9711 35.3912 32.6476 33.4786 30.1555 32C32.6476 30.5215 33.9711 28.6088 35.147 26.9045C37.0831 24.1013 38.4828 22.077 45.5211 22.077H51.8828L48.7019 25.2846L52.1805 28.7924Z"
        fill="#C42125"
        {...pathProps}
      />
    </svg>
  );
};

const GearsIcon = ({ svgProps, pathProps }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} viewBox="5 0 65 64" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0999 21.527V16.8704H54.2804C54.0731 16.0716 53.7607 15.3241 53.3639 14.6278L55.7729 12.2163L52.2836 8.727L49.8695 11.136C49.1732 10.7366 48.4257 10.4268 47.6244 10.2195V6.39996H42.9729V10.2195C42.1742 10.4268 41.4267 10.7366 40.7303 11.136L38.3188 8.727L34.827 12.2163L37.2385 14.6278C36.8417 15.3241 36.5294 16.0716 36.3195 16.8704H32.4999V21.5244H36.3169C36.5268 22.3232 36.8391 23.0707 37.2359 23.7696L34.8244 26.1811L38.3163 29.6704L40.7278 27.264C41.4241 27.6608 42.1716 27.9731 42.9703 28.1804V32H47.6219V28.183C48.4231 27.9731 49.1681 27.6633 49.867 27.2665L52.2785 29.6729L55.7678 26.1836L53.3588 23.7721C53.7556 23.0732 54.0679 22.3257 54.2753 21.527H58.0999ZM45.3002 24.32C42.4714 24.32 40.1802 22.0262 40.1802 19.2C40.1802 16.3737 42.4714 14.08 45.3002 14.08C48.129 14.08 50.4202 16.3737 50.4202 19.2C50.4202 22.0262 48.129 24.32 45.3002 24.32ZM52.2786 37.1199H46.964C44.8495 43.0796 39.1714 47.3599 32.5 47.3599C24.029 47.3599 17.14 40.4735 17.14 31.9999C17.14 25.3286 21.4255 19.6531 27.38 17.5359V12.2214C25.0786 12.8204 22.9128 13.7599 20.9979 15.0655L17.1119 11.182L11.6821 16.6118L15.5682 20.4979C14.26 22.4153 13.2898 24.5708 12.6959 26.8799H6.90002V37.1199H12.6959C13.2898 39.4316 14.26 41.5846 15.5682 43.502L11.6821 47.3881L17.1119 52.8204L20.9979 48.9343C22.9154 50.2399 25.0735 51.2076 27.38 51.8067V57.5999H37.62V51.8067C39.9266 51.2102 42.0847 50.2399 44.0021 48.9343L47.8882 52.8204L53.3179 47.3881L49.4319 43.502C50.7375 41.5871 51.6795 39.4214 52.2786 37.1199Z"
        fill="white"
        {...pathProps}
      />
    </svg>
  );
};

export {
  YouTubeIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  LinkIcon,
  BoxIcon,
  LoginIcon,
  ToolKitIcon,
  AutoAnalysisIcon,
  BranchIcon,
  DocumentCurlIcon,
  ScriptIcon,
  ForkIcon,
  GearsIcon
};
