import { OidcClient } from 'oidc-client-ts';
import { loadApiDetails } from './endpoints';

export class OIDC {
  static OIDCClient: OidcClient;

  static async getOIDCClient() {
    if (!OIDC.OIDCClient) {
      const { API_URL, AUTH_PROVIDER } = await loadApiDetails();
      OIDC.OIDCClient = new OidcClient({
        authority: AUTH_PROVIDER,
        client_id: 'developer_hub',
        redirect_uri: `${window.location.origin}/oidc/callback`,
        response_type: 'code',
        resource: API_URL,
        scope: 'scripts.read',
      });
    }
  }

  static async signIn() {
    await OIDC.getOIDCClient();
    const { url } = await OIDC.OIDCClient.createSigninRequest({});
    return url;
  }

  static async signOut() {
    await OIDC.getOIDCClient();
    const { url } = await OIDC.OIDCClient.createSignoutRequest({});
    return url;
  }

  static async processSigninResponse(url: string) {
    await OIDC.getOIDCClient();
    return await OIDC.OIDCClient.processSigninResponse(url);
  }
}
