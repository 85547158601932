import React, { useState, useEffect, useRef } from 'react';
import {
  Navbar,
  truncate,
  BannerLayout,
  Footer,
  FilterGroup,
  queryKeys,
  fetchCodeSampleList,
  getFilters,
  getFeaturedCodeSamples,
  debounce,
  OIDC,
  cookie,
} from '@devportal/common';
import {
  Card,
  CardContent,
  Pagination,
  InputAdornment,
  TextField,
  CircularProgress,
  Skeleton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { SearchIcon, CloseIcon } from '@diligentcorp/atlas-react-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { WithNavLayout } from 'components';

function CodeSamplesMainPage(props) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const [filterCheckMap, setFilterCheckMap] = useState({});
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const accessToken = cookie.get('accessToken');

  const { data: codeSamples, isLoading: isSampleListLoading } = useQuery(
    [
      queryKeys.CODE_SAMPLE_LIST,
      {
        filter: searchParams?.get('filter') || '',
        searchText: searchParams?.get('search-text') || '',
        recordsPerPage: searchParams?.get('records-per-page') || '10',
        page: searchParams?.get('page') || '1',
      },
    ],
    fetchCodeSampleList,
    {
      onSuccess: (data) => {
        setPagesCount(Math.ceil(Number(data?.data?.total / 10)));
      },
    }
  );

  const sampleList = codeSamples?.data?.attributes || [];

  const { data: filters, isLoading: isFiltersLoading } = useQuery(
    [
      queryKeys.CODE_SAMPLE_FILTERS,
      {
        filterPage: 'code_samples',
      },
    ],
    getFilters
  );

  const { data: featuredSamples, isLoading: isFeaturedLoading } = useQuery(
    [queryKeys.CODE_SAMPLE_FEATURED],
    getFeaturedCodeSamples
  );

  const featuredSampleData = featuredSamples?.data?.attributes || [];
  const filtersGroup = {};

  filters?.data?.attributes?.forEach((item) => {
    filtersGroup[item.filter_type] = filtersGroup[item.filter_type] || [];
    filtersGroup[item.filter_type].push(item);
  });

  useEffect(() => {
    const filters = searchParams.get('filter');
    const arr = filters?.split('-') || [];

    setFilterCheckMap((olds) => {
      const oldsObj = JSON.parse(JSON.stringify(olds));
      Object.keys(oldsObj).forEach((key) => {
        oldsObj[key] = false;
      });
      arr.forEach((id) => {
        oldsObj[id] = true;
      });
      return oldsObj;
    });
  }, [window?.location?.href]);

  const queryUpdater = () => {
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const filtersParamUpdater = (updatedFilters) => {
    if (updatedFilters?.length) {
      searchParams.set('filter', updatedFilters.join('-'));
    } else {
      searchParams.delete('filter');
    }
    searchParams.set('page', '1');
    queryUpdater();
  };

  const onChangeHandler = (id, value) => {
    setFilterCheckMap((olds) => ({ ...olds, [id]: value }));

    const updatedFilters =
      searchParams
        ?.get('filter')
        ?.split('-')
        ?.filter((item) => item != id) || [];

    if (value) updatedFilters.push(id);

    filtersParamUpdater(updatedFilters);
  };

  const filterSectionClearHandler = (ids) => {
    setFilterCheckMap((olds) => {
      const oldsObj = JSON.parse(JSON.stringify(olds));
      ids.forEach((id) => {
        oldsObj[id] = false;
      });
      return oldsObj;
    });

    const updatedFilters =
      searchParams
        ?.get('filter')
        ?.split('-')
        ?.filter((item) => !ids.includes(item)) || [];

    filtersParamUpdater(updatedFilters);
  };

  const onSearchHandler = debounce((e) => {
    if (e?.target?.value?.length) {
      searchParams.set('search-text', e.target.value);
    } else {
      searchParams.delete('search-text');
    }
    searchParams.set('page', '1');
    queryUpdater();
  }, 1000);

  const onSampleClick = (id) => {
    if (accessToken?.length) {
      history.push(`/code-samples/${id}`);
    } else {
      setIsLoginModal(id);
    }
  };

  const inpRef: any = useRef();

  useEffect(() => {
    const val = searchParams?.get('search-text');
    if (val && val?.length) {
      inpRef.current.value = val;
    }
  }, [window?.location?.href]);

  const modalLoginClick = async () => {
    window.localStorage.setItem('redirectPath', `${window.location.origin}/code-samples/${isLoginModal}`);
    setIsLoginLoading(true);
    const  url  = await OIDC.signIn();
    const windowProps = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1200, height=800`;
    window.open(url, '_blank', windowProps);
  };

  return (
    <WithNavLayout>
      <Dialog fullScreen={false} open={!!isLoginModal} onClose={() => setIsLoginModal(false)}>
        <DialogTitle>
          Login Required
          <IconButton aria-label="ArrowRight" onClick={() => setIsLoginModal(false)} color="inherit">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Please log in to view this Script</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined">Request a Demo</Button>
          <Button variant="contained" disabled={isLoginLoading} autoFocus onClick={modalLoginClick}>
            Log in {isLoginLoading ? <CircularProgress size={20} /> : null}
          </Button>
        </DialogActions>
      </Dialog>

      <BannerLayout
        type="light"
        breadcrumpList={[
          { name: 'Home', href: '/' },
          { name: 'Code Samples', href: '/code-samples' },
        ]}
        title="Code Samples"
        description="Ready to use code samples to get started with analysis, automation and integration using Diligent developer tools"
        isCardsLoading={isFeaturedLoading}
        cardAreaLabel="FEATURED CODE SAMPLES"
        cardsData={{
          list: featuredSampleData,
          titleKey: 'cs_title',
          descriptionKey: 'cs_description',
          idKey: 'cs_id',
          clickHandler: onSampleClick,
          linkPrefix: '/code-samples',
        }}
      />

      <div className="flex">
        <div className="w-80 py-8">
          <div className="flex justify-between items-center font-semibold px-10">
            <p className="text-black">FILTERS</p>
            {Object.values(filterCheckMap).some((item) => item) ? (
              <p
                className="text-xs text-brand-300 cursor-pointer"
                onClick={() => {
                  setFilterCheckMap({});
                  filtersParamUpdater([]);
                }}
              >
                Reset Filters
              </p>
            ) : null}
          </div>

          <div className="px-10">
            <div style={{ height: '1px', backgroundColor: '#D7D7D7' }}></div>
          </div>

          <div className="px-10 overflow-auto" style={{ maxHeight: '86vh' }}>
            {isFiltersLoading ? (
              Array.from(new Array(22)).map((i) => <Skeleton height={40} />)
            ) : !filters?.data?.attributes?.length ? (
              <>No data found</>
            ) : (
              Object.keys(filtersGroup).map((key) => {
                return (
                  <FilterGroup
                    heading={`Filter by ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                    onClear={() => {
                      filterSectionClearHandler(filtersGroup[key].map((item) => item.filter_id));
                    }}
                    items={filtersGroup[key]}
                    checkMap={filterCheckMap}
                    onChangeHandler={onChangeHandler}
                  />
                );
              })
            )}
          </div>
        </div>

        <div className="bg-lightgrey-100 w-full">
          <div className="container mx-auto w-[80%] py-10">
            <TextField
              size="medium"
              className="w-full bg-white mb-6"
              placeholder="Search by Name, Description and tags..."
              onChange={onSearchHandler}
              inputRef={inpRef}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <div className="overflow-auto" style={{ height: '80vh' }}>
              {isSampleListLoading ? (
                <div className="flex justify-center items-center w-full">
                  <CircularProgress size={55} />
                </div>
              ) : !sampleList?.length ? (
                <div>No data found</div>
              ) : (
                sampleList.map((item, i) => {
                  return (
                    <Card
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      className={`cursor-pointer ${i == 0 ? '' : 'mt-3'}`}
                      onClick={() => {
                        onSampleClick(item?.cs_id);
                      }}
                    >
                      <CardContent>
                        <div className="text-text-200 font-bold">{item?.cs_title}</div>
                        <div className="text-sm">{truncate(item?.cs_description, 340)}</div>
                      </CardContent>
                    </Card>
                  );
                })
              )}
            </div>
            <div className="flex justify-end mt-2">
              <Pagination
                count={Math.ceil(Number(codeSamples?.data?.total / 10)) || pagesCount}
                sx={{ p: 0 }}
                page={Number(searchParams?.get('page')) || 1}
                onChange={(e, page) => {
                  searchParams.set('page', String(page));
                  queryUpdater();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </WithNavLayout>
  );
}
export { CodeSamplesMainPage };
